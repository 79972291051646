import React, { useState, useEffect } from "react";
import "./App.css";
import FireworkAnimation from "./FireworkAnimation";
import logo from "./img/fs-logo.png";
import eyeLogo from "./img/eye-fill.png";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { resetData } from "./features/login/LoginSlice";
// import "react-toastify/dist/ReactToastify.css";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { DialogContent, DialogTitle } from "@mui/material";
import { Dialog, DialogActions, DialogContentText } from "@material-ui/core";
import { Loader as LoaderIcon } from "react-feather";
import { Modal, Spinner } from "react-bootstrap";

function App() {
  const { authData, role } = useSelector((state) => state.loginSlice);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [questionData, setQuestionData] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(20);
  const [isOpen, setisOpen] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [submitLoading, setsubmitLoading] = useState("");

  useEffect(() => {
    // const confirmDelete = window.confirm(
    //   "Do you want to delete used questions?"
    // );
    // if (confirmDelete) {
    //   // deleteUsedQuestions();
    // }
    fetchCategories();
  }, []);

  const deleteUsedQuestions = async () => {
    try {
      setsubmitLoading(true);
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/reset",
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authData}`, // Add token here
            "Content-Type": "application/json", // Optional, but good to specify
          },
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        toast.success(data.message);
        handleClear();
        setisOpen(false);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error deleting questions:", error);
      toast.error("An error occurred while deleting questions.");
    } finally {
      setsubmitLoading(false);
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    // setOpenAlert(false);
    dispatch(resetData());
    navigate("/", { replace: true });
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/getCategories",
        {
          method: "GET", // Optional since GET is the default
          headers: {
            Authorization: `Bearer ${authData}`, // Add token here
            "Content-Type": "application/json", // Optional, but good to specify
          },
        }
      );
      const data = await response.json();
      console.log(data.data);

      setCategories(data?.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategoryId(categoryId);
  };

  const handleGetQuestion = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `http://quiz.clouddeploy.in/api/v1/quiz/getQuestionByCategoryId/${selectedCategoryId}`,
        {
          method: "GET", // Optional since GET is the default
          headers: {
            Authorization: `Bearer ${authData}`, // Add token here
            "Content-Type": "application/json", // Optional, but good to specify
          },
        }
      );
      const questionData = await response.json();
      if (questionData.status === "failure") {
        setErrorMessage(
          "All the questions in this category have been used.Please select another category"
        );
        return;
      }
      setErrorMessage("");
      if (questionData.status === "error") {
        toast.error(questionData.message);
        return;
      }

      setQuestionData(questionData.data);
      setShowAnswer(false);
      setTimer(20);
    } catch (error) {
      console.error("Error fetching question:", error);
      toast.error("An error occurred while fetching the question.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timerInterval;
    if (questionData && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [questionData, timer]);

  const handleRevealAnswer = () => setShowAnswer(!showAnswer);

  const handleClear = () => {
    // setSelectedCategoryId("");
    setQuestionData(null);
    setShowAnswer(false);
    setTimer(20);
  };

  return (
    <div className="mainContainer h-full py-4">
      {/* <FireworkAnimation /> */}

      <main>
        <div className="mx-10">
          <header className="leftcontainer">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-3 text-left">
                <img src={logo} alt="logo" />
              </div>
              <div className="col-md-6">
                <div className="mainHeading mt-4 pb-4 mt-lg-0 pb-lg-0 quizHeading">
                  Quiz
                </div>
              </div>
              {role === "user" && (
                <div className="flex justify-end mx-4 col-md-2">
                  <button onClick={handleLogout} class="logoutBtn">
                    <RiLogoutCircleRLine size={22} /> Logout
                  </button>
                </div>
              )}
            </div>
          </header>

          <div className="mt-4">
            <div className="row">
              <div className="col-md-4">
                <div className="leftWhitecontainer ">
                  <div className="flex items-end justify-end">
                    {" "}
                    <button
                      className="resetBtn"
                      onClick={() => setisOpen(true)}
                    >
                      Reset Questions
                    </button>
                  </div>
                  <h1 className="mb-4 categoryHeading">Choose Category</h1>
                  <select
                    onChange={handleCategoryChange}
                    value={selectedCategoryId}
                  >
                    <option value="">-- Select Category --</option>
                    {categories?.map((category) => (
                      <option
                        key={category.iCategoryId}
                        value={category.iCategoryId}
                        className="selectOption"
                      >
                        {category.sCategoryName}
                      </option>
                    ))}
                  </select>
                  <button
                    disabled={!selectedCategoryId || questionData || loading} // Disable when loading
                    onClick={handleGetQuestion}
                  >
                    {loading ? "Loading..." : "Get Question"}{" "}
                  </button>
                </div>
              </div>
              <div className="col-md-8 leftcontainer">
                <div className="rightwhitecontainer mt-4 mt-lg-0">
                  {ErrorMessage && (
                    <span className="text-red-600">{ErrorMessage}</span>
                  )}
                  <div className="flex justify-between">
                    {questionData && (
                      <span className="font-semibold text-xl">
                        Remaining Questions:
                        {questionData?.remainingQuestions || 0}
                      </span>
                    )}
                  </div>

                  {questionData && (
                    <>
                      <strong>Q. {questionData.question}</strong>
                      <ul>
                        {questionData?.options.map((option, index) => (
                          <li key={index}>{option}</li>
                        ))}
                      </ul>

                      <div
                        className={`timer ${
                          timer === 0 ? "time-up" : "time-running"
                        }`}
                      >
                        {timer > 0
                          ? `Time Remaining: ${timer} seconds`
                          : "Time up!"}
                      </div>

                      <button onClick={handleRevealAnswer}>
                        Reveal Answer <img src={eyeLogo} alt="eye" />
                      </button>
                      {showAnswer && (
                        <div className="correctanswer">
                          <textarea readOnly>{questionData.answer}</textarea>
                        </div>
                      )}

                      <div className="d-flex justify-content-end mt-3">
                        <button className="clear-button" onClick={handleClear}>
                          Clear
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        pauseOnFocusLoss
        theme="light"
      /> */}
      <Dialog open={isOpen} onClose={() => setisOpen(false)}>
        <DialogTitle className="newpopupstyle"></DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reset the all the used questions?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {submitLoading ? (
            <button
              // className={classes.delete}
              //   style={{ width: "100px" }}
              className="delete"
              disabled={submitLoading}
            >
              <LoaderIcon size={20} className="indicator" />
            </button>
          ) : (
            <button
              onClick={deleteUsedQuestions}
              // color="danger"
              disabled={submitLoading}
              className="delete"
            >
              {submitLoading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                "Yes"
              )}
            </button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;
