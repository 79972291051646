import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { useNavbar } from "../../customHooks/Navbar/useNavbar";
import { LuSearchCode } from "react-icons/lu";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  ProSidebarProvider,
} from "react-pro-sidebar";
import { FaUser, FaProductHunt } from "react-icons/fa";
import { FiLogOut, FiMenu } from "react-icons/fi";
import styles from "./Navbar.module.css";
import { useMediaQuery } from "react-responsive";
import { IoClose } from "react-icons/io5";
import { MdSettings } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { resetData } from "../features/login/LoginSlice";

const NavBar = () => {
  // const hookProps = useNavbar();
  const { role } = useSelector((state) => state.loginSlice);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const {
  //   openAlert,
  //   handleClose,
  //   handleLogout,
  //   handleOpen,
  //   tabs,
  //   openIndex,
  //   setOpenIndex,
  //   handleItemClick,
  // } = hookProps;
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleLogout = () => {
    // setOpenAlert(false);
    dispatch(resetData());
    navigate("/", { replace: true });
  };
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <>
      {isDesktop ? (
        <>
          <ProSidebarProvider>
            <Sidebar className={styles.leftsidebar} collapsed={isCollapsed}>
              {/* Logo Section */}
              <div className={styles.mainlogo}>
                <Link to={"/"}>
                  {isCollapsed ? (
                    <img
                      src="/iAugment-icon.png"
                      alt="Logo"
                      className={styles.mainlogoicon}
                    />
                  ) : (
                    <img src="/iAugment-logo-color.png" alt="Logo" />
                  )}
                </Link>
              </div>

              {/* Menu Toggle Icon */}
              <div className={styles.menuicon}>
                <FiMenu
                  size={26}
                  onClick={handleToggle}
                  style={{ cursor: "pointer" }}
                />
              </div>

              {/* Sidebar Menu */}
              <Menu>
                <div
                  className={`${
                    location.pathname.includes("/quiz")
                      ? `${styles.bgverification} text-white`
                      : ""
                  } hover:no-underline hover:text-black  ${styles.logouticon}`}
                >
                  <Menu>
                    <Link
                      to={"/quiz"}
                      className={`${
                        location.pathname.includes("/quiz")
                          ? `${styles.bgverification} text-white`
                          : ""
                      } hover:no-underline`}
                    >
                      <MenuItem>Quiz</MenuItem>
                    </Link>
                  </Menu>
                </div>
                {role.toLowerCase() === "admin" && (
                  <>
                    <div
                      className={`${
                        location.pathname.includes("/upload")
                          ? `${styles.bgverification} text-white`
                          : ""
                      } hover:no-underline hover:text-black  ${
                        styles.logouticon
                      }`}
                    >
                      <Menu>
                        <Link
                          to={"/upload"}
                          className={`${
                            location.pathname.includes("/upload")
                              ? `${styles.bgverification} text-white`
                              : ""
                          } hover:no-underline`}
                        >
                          {" "}
                          <MenuItem>Upload</MenuItem>{" "}
                        </Link>
                      </Menu>
                    </div>

                    <div
                      className={`${
                        location.pathname.includes("/questions")
                          ? `${styles.bgverification} text-white`
                          : ""
                      } hover:no-underline hover:text-black  ${
                        styles.logouticon
                      }`}
                    >
                      <Menu>
                        <Link
                          to={"/questions"}
                          className={`${
                            location.pathname.includes("/questions")
                              ? `${styles.bgverification} text-white ${styles.logouticon}`
                              : ""
                          } hover:no-underline`}
                        >
                          <MenuItem>Questions</MenuItem>{" "}
                        </Link>
                      </Menu>
                    </div>
                  </>
                )}
              </Menu>

              {/* Logout Button */}
              <div className={styles.logouticon}>
                <Menu>
                  <MenuItem icon={<FiLogOut />} onClick={handleLogout}>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </Sidebar>
          </ProSidebarProvider>
        </>
      ) : (
        <>
          <div>
            {/* Top Navbar */}
            <nav className={styles.topNavbar}>
              <div className={styles.logo}>
                <Link to={"/"}>
                  <img
                    src="/iAugment-logo-color.png"
                    alt="Logo"
                    className={styles.mainLogo}
                  />
                </Link>
              </div>
              <div className={styles.menuIcon}>
                {!isMenuOpen ? (
                  <FiMenu
                    size={26}
                    onClick={toggleMenu}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <IoClose
                    size={26}
                    onClick={toggleMenu}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </nav>

            {/* Dropdown Menu */}
            {isMenuOpen && (
              <div className={styles.menuWrapper}>
                <ul className={styles.menuList}>
                  {/* Logout Button */}
                  <li className={styles.menuItem}>
                    <Link
                      to={"/quiz"}
                      className={`${
                        location.pathname.includes("quiz")
                          ? "text-blue-600"
                          : ""
                      }`}
                    >
                      Quiz
                    </Link>
                  </li>
                  {role.toLowerCase() === "admin" && (
                    <>
                      <li className={styles.menuItem}>
                        <Link
                          to={"/upload"}
                          className={`${
                            location.pathname.includes("upload")
                              ? "text-blue-600"
                              : ""
                          }`}
                        >
                          Upload
                        </Link>
                      </li>
                      <li className={styles.menuItem}>
                        <Link
                          to={"/question"}
                          className={`${
                            location.pathname.includes("question")
                              ? "text-blue-600"
                              : ""
                          }`}
                        >
                          Questions
                        </Link>
                      </li>
                    </>
                  )}

                  <li className={styles.menuItem}>
                    <button
                      onClick={handleLogout}
                      className={styles.logoutButton}
                    >
                      <FiLogOut /> Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default NavBar;
