import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthPage from "./AuthPage/AuthPage";
import QuizPage from "./QuizPage";
import FullPageLoading from "./FullPageLoading/FullPageLoading";
import UploadQuestions from "./UploadQuestions";
import Layout from "./Layout/Layout";
import "./App.css";
import QuestionList from "./QuestionList";
// import "bootstrap/dist/css/bootstrap.min.css";

// Loader

function App() {
  const { authData } = useSelector((state) => state.loginSlice);

  if (!authData) {
    return <AuthPage />;
  }
  return (
    <Router>
      <Layout>
        <>
          <Suspense fallback={<FullPageLoading />}>
            <Routes>
              <Route path="/" exact element={<QuizPage />} />
              <Route path="/quiz" exact element={<QuizPage />} />
              <Route path="/upload" exact element={<UploadQuestions />} />
              <Route path="/questions" exact element={<QuestionList />} />
            </Routes>
          </Suspense>
        </>
      </Layout>
    </Router>
  );
}

export default App;
