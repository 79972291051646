import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const MultiExcelUpload = () => {
  const { authData } = useSelector((state) => state.loginSlice);
  const [fileData1, setFileData1] = useState(null);
  const [fileData2, setFileData2] = useState(null);
  const [jsonData1, setJsonData1] = useState(null);
  const [jsonData2, setJsonData2] = useState(null);
  const [error, setError] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [lastId, setlastId] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getCategories();
    getLastId();
  }, []);

  const handleFileUpload = (event, setFileData, setJsonData) => {
    const file = event.target.files[0];

    if (
      !file ||
      ![
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ].includes(file.type)
    ) {
      setError("Please upload a valid Excel file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      let data = XLSX.utils.sheet_to_json(worksheet);

      if (data.length === 0) {
        setError("The uploaded Excel file is empty or improperly formatted.");
        return;
      }
      data = data.map((row) => {
        let { iAnswerId, ...rest } = row;
        return rest;
      });
      setError("");
      setFileData(file);
      setJsonData(data);
    };
    reader.readAsBinaryString(file);
  };

  const handleUpload = async () => {
    if (!jsonData1 || !jsonData2) {
      setError("Please upload both Excel files before proceeding.");
      return;
    }

    try {
      const payload = { questionJson: jsonData1, answerJson: jsonData2 };
      const response = await axios.post(
        "http://quiz.clouddeploy.in/api/v1/quiz/upload",
        { ...payload, iCategoryId: category },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authData}`, // Add the token here
          },
        }
      );

      alert("Data uploaded successfully!");
      setFileData1(null);
      setFileData2(null);
      setJsonData1(null);
      setJsonData2(null);
      navigate("/questions");
    } catch (err) {
      console.error(err);
      setError("Failed to upload data. Please try again.");
    }
  };

  const getCategories = async () => {
    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/getCategories",
        {
          method: "GET", // Optional since GET is the default
          headers: {
            Authorization: `Bearer ${authData}`, // Add token here
            "Content-Type": "application/json", // Optional, but good to specify
          },
        }
      );
      const data = await response.json();

      setCategories(data?.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getLastId = async () => {
    try {
      const response = await fetch(
        "http://quiz.clouddeploy.in/api/v1/quiz/lastQuestionId",
        {
          method: "GET", // Optional since GET is the default
          headers: {
            Authorization: `Bearer ${authData}`, // Add token here
            "Content-Type": "application/json", // Optional, but good to specify
          },
        }
      );
      const data = await response.json();

      setlastId(data?.data.lastInsertedQuestionId);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const renderTable = (data) => {
    if (!data || data.length === 0) return null;

    const headers = Object.keys(data[0]);

    return (
      <div className="h-[50vh] overflow-x-scroll">
        <table className="table-bordered min-w-full divide-y divide-gray-200 my-3 rounded-lg overflow-hidden bg-white shadow-md table-responsive-sm">
          <thead>
            <tr className="bg-gray-100">
              {headers.map((header) => (
                <th
                  key={header}
                  className="border border-gray-300 px-4 py-2 font-medium text-gray-700"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index} className="even:bg-gray-50">
                {headers.map((header) => (
                  <td
                    key={header}
                    className="border border-gray-300 px-4 py-2 text-gray-600"
                  >
                    {row[header]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="p-6  mx-auto bg-white">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">
        Upload Two Excel Files
      </h1>
      <div className="font-semibold text-xl mb-4">
        Last Inserted Question Id:{lastId}
      </div>
      <div className="flex gap-4">
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Select the category you want to upload the questions
          </label>
          <select
            name="categories"
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value={""}>Select Option</option>
            {categories?.map((cate) => {
              return (
                <option value={cate.iCategoryId}>{cate.sCategoryName}</option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            File 1(Questions):
          </label>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => handleFileUpload(e, setFileData1, setJsonData1)}
            className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={!category}
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            File 2(Answers):
          </label>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => handleFileUpload(e, setFileData2, setJsonData2)}
            className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={!category}
          />
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-700 mb-2">
          Preview of File 1:
        </h2>
        {renderTable(jsonData1) || (
          <p className="text-gray-500">No data uploaded yet.</p>
        )}
      </div>

      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-700 mb-2">
          Preview of File 2:
        </h2>
        {renderTable(jsonData2) || (
          <p className="text-gray-500">No data uploaded yet.</p>
        )}
      </div>
      {error && <p className="text-red-600 text-sm mb-6">{error}</p>}

      <button
        className={`py-2 px-4 rounded-md font-medium transition duration-200 ${
          !jsonData1 && !jsonData2
            ? "bg-gray-400 text-gray-700 cursor-not-allowed"
            : "bg-blue-500 text-white hover:bg-blue-600"
        }`}
        disabled={!jsonData1 && !jsonData2}
        onClick={handleUpload}
      >
        Upload
      </button>
    </div>
  );
};

export default MultiExcelUpload;
